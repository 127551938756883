var generic = generic || {};
var site = site || {};

(function ($) {
  Drupal.behaviors.localeInterstitial = {
    attach: function (context) {
      var $prefCountry, $prefLanguage;
      var isRendered = 0;
      var $interstitialContainer = $('#localeInterstitial', context);
      var $countryContainer = $('#localeInterstitial .country-list', context);
      var $countryLink = $('#localeInterstitial a.country-link', context);
      var $selectedCountry = $('.current-country', context);
      var $languageList = $('#localeInterstitial .language-list', context);
      var $languageLink = $('#localeInterstitial .language-list li', context);
      var $localeButton = $('#localeInterstitial #chooseLocaleButton', context);
      var resizeEvt;
      var locale = generic.cookie('LOCALE'); // get locale settings from browser if it's set

      if ([undefined, null, '', 'en_EU'].indexOf(locale) > -1) {
        displayLocaleInterstitial();
        $('.block-template-country-picker-eu-v1', context).hide(); // hide country picker when interstitial is being shown, otherwise can be seen in bg as an empty rect
      }

      $('body').on('displayLocaleInterstitial', function () {
        // action to let other modules show the enterstitial (e.g. mobile country picker)
        displayLocaleInterstitial();
      });

      $countryLink.on('click', function () {
        $countryContainer.find('input[type=radio]').prop('checked', false);
        $(this).find('input[type=radio]').prop('checked', true);
        $prefCountry = $(this).data('country').toUpperCase();
        $countryLink.removeClass('selected');
        $(this).addClass('selected');
        $selectedCountry.html($(this).data('countryname'));
        $languageLink.removeClass('selected');
        var $countryLangs = $(this).data('languages').split(',');

        if (!$prefLanguage || $countryLangs.indexOf($prefLanguage) === -1) {
          // set preferred language to country's default if not defined or not available
          $prefLanguage = $countryLangs[0];
        }
        $languageList.html(site.getLangsFromCountryLink($(this)).join(''));
        $languageLink.removeClass('selected current-lang');
        $interstitialContainer.find('.language-list li').each(function () {
          $(this).append('<br><input type="radio">');
          if ($(this).data('language') === $prefLanguage) {
            $(this).trigger('click');
          }
        });
        if ($prefCountry && $prefLanguage) {
          $localeButton.prop('disabled', false); // enable the choose locale button once both locale variables are set
        } else {
          $localeButton.prop('disabled', true); // disable the choose locale button unless both locale variables are set
        }
        $('#localeInterstitial .initial-hide', context).show();
        site.centerModal($interstitialContainer);
      });

      $('body').on('click', '#localeInterstitial .language-list li', function () {
        $languageList.find('input[type=radio]').prop('checked', false);
        $(this).find('input[type=radio]').prop('checked', true);
        $prefLanguage = $(this).data('language');
        $languageList.find('li').removeClass('selected current-lang');
        $(this).addClass('selected');
        if ($prefCountry && $prefLanguage) {
          $localeButton.prop('disabled', false); // enable the choose locale button once both locale variables are set
        } else {
          $localeButton.prop('disabled', true); // disable the choose locale button unless both locale variables are set
        }
      });

      $localeButton.on('click', function () {
        site.changeLocale($prefCountry, $prefLanguage);
        var newHref = window.location.protocol + '//' + window.location.host.replace(/\/$/, '');
        var analyticsHref = newHref + '/' + $prefCountry.toLowerCase() + '/' + $prefLanguage;
        var analyticsBrandLocale = Drupal.settings.site_name + ' ' + $prefCountry;
        var obj = {
          hub_referrer: document.referrer,
          country_code: $prefCountry,
          brand_locale_space: analyticsBrandLocale,
          location: analyticsHref
        };

        site.track.evtView(obj);
      });

      $(window).on(
        'resize',
        _.debounce(function () {
          clearTimeout(resizeEvt);
          resizeEvt = setTimeout(function () {
            site.centerModal($interstitialContainer);
          }, 250);
        })
      );

      function displayLocaleInterstitial() {
        generic.jsonrpc.fetch({
          method: 'locale.detect',
          params: [],
          onSuccess: function (jsonRpcResponse) {
            setOverlayLocale(jsonRpcResponse.getValue());
          },
          onFailure: function () {
            setOverlayLocale();
          }
        });
        if (isRendered === 0) {
          // Ensure that function is triggered only once.
          formatLocaleOptions();
        }
        site.centerModal($interstitialContainer);
      }

      function formatLocaleOptions() {
        $('#localeInterstitial li a', context).each(function () {
          var $flag = $(this).find('img').remove()[0];
          var $text = $(this).html();

          $(this).empty();
          $(this).append($text + '<br>');
          $(this).append($flag, '<br>');
          $(this).append('<input type="radio">');
        });
        $(
          '#localeInterstitial .country-list, #localeInterstitial .language-list',
          context
        ).cleanWhitespace();
        isRendered = 1;
      }

      function setOverlayLocale(overlayLocale) {
        if (!overlayLocale) {
          $localeButton.prop('disabled', true); // disable the choose locale button unless a locale is provided
        } else {
          locale = overlayLocale; // default country and language selections
          var localeParts = locale.split('_'); // don't need local variable

          $prefLanguage = localeParts[0].toLowerCase();
          $prefCountry = localeParts[1].toLowerCase();
        }

        if ($prefCountry && $prefLanguage) {
          $localeButton.prop('disabled', false); // enable the choose locale button once both locale variables are set
        } else {
          $localeButton.prop('disabled', true); // disable the choose locale button unless both locale variables are set
        }

        site.setupLanguageMenu($interstitialContainer, $prefCountry, $prefLanguage);

        if ($prefCountry !== '') {
          $('#localeInterstitial .country-list a.country-link', context).each(function () {
            if ($(this).data('country') === $prefCountry) {
              $(this).trigger('click');
            }
          });
        }

        if ($prefLanguage !== '') {
          $('#localeInterstitial .language-list li', context).each(function () {
            if ($(this).data('language') === $prefLanguage) {
              $(this).trigger('click');
            }
          });
        }

        // finally, append the interstitial to the end of the page
        $('body').append($('#localeOverlay').css({ display: 'block' }));
        site.centerModal($interstitialContainer);
      }
    }
  };
})(jQuery);
